import { get, first, compact } from 'lodash';
import { HTTP_GONE } from 'client/utils/http-status';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { getCarVinSchema } from 'client/seo/schemas/car';
import { getModelState } from 'client/data/luckdragon/model';
import { InventoryPaths, InventoryModel } from 'client/data/models/inventory';
import { buildVehiclePath } from 'client/data/models/vehicle';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { getSeoImage } from 'site-modules/shared/utils/inventory-utils/get-seo-image';
import {
  getMakeLinkText,
  getMakeLinkUrl,
  getMakeModelLinkText,
  getMakeModelLinkUrl,
  getMakeModelYearLinkText,
  getMakeModelYearLinkUrl,
} from 'site-modules/shared/utils/vehicle-link-constructor';
import { getHomeBreadcrumb } from 'site-modules/shared/components/breadcrumbs/helper';
import { makeNiceName as getNiceName } from 'site-modules/shared/utils/nice-name';
import { NON_TMV_DEALERS } from 'site-modules/shared/constants/inventory/non-tmv-dealers';
import { appendTrademarkCharacter } from 'site-modules/shared/utils/inventory-utils/append-trademark-character';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { isWarrantyApplicable } from 'site-modules/shared/utils/inventory/warranty';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { isDriveway, isPartnerDealer } from 'site-modules/shared/utils/dealer-details-utils';
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';
import { getPublicationStateByType } from 'site-modules/shared/utils/publication-states';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';

export const VIN_OVERVIEW_ID = 'edm-entry-vin-overview';
export const VIN_OVERVIEW_CPO_ID = 'edm-entry-vin-overview-cpo';
export const IMAGE_DATA_PATH = 'vehicleInfo.photo.defaultPhoto.largePhotoCount';
const BASE_PAGE_NAME = 'model_car_inventory_vin_detail';
const BASE_CATEGORY_NAME = 'model_car_inventory';

/**
 * Compare request params with api
 * @param make {string}
 * @param model {string}
 * @param year {string}
 * @param vinData {object}
 * @returns {boolean}
 */
export function compareMakeModelYearWithVin(make, model, year, vinData) {
  const styleInfo = get(vinData, 'vehicleInfo.styleInfo', {});
  const vinMake = getNiceName(styleInfo.make);
  const vinModel = getNiceName(styleInfo.model);
  const vinYear = `${styleInfo.year}`;
  return make !== vinMake || model !== vinModel || year !== vinYear;
}

/**
 * Handle 404 for vdp
 *
 * @param vinPath
 * @return {Error}
 */
export function get410Error(vinPath) {
  const errorMsg = `VehicleValidatorError - Could not find vin information for ${vinPath}`;
  const ex = new Error(errorMsg);
  ex.status = HTTP_GONE;
  return ex;
}

export function getVinOverviewId(isCpo) {
  return isCpo ? VIN_OVERVIEW_CPO_ID : VIN_OVERVIEW_ID;
}

export function getVinPath(vin) {
  return vin ? `vin["${vin.toUpperCase()}"]` : null;
}

export function getVinWarrantyPath(vinData) {
  const vin = get(vinData, DATA_PATH.VIN);
  return vin && isWarrantyApplicable(vinData) ? InventoryPaths.buildVinWarrantyPath(vin) : null;
}

export function getBOLVinWarrantyPath(vinData) {
  const type = get(vinData, DATA_PATH.INVENTORY_TYPE);
  return isUsed(type) ? getVinWarrantyPath(vinData) : null;
}

export function getVinWithFallbackPath(vin) {
  return vin ? `vinWithFallback["${vin}"]` : null;
}

export function getSimilarVinsPath(vin) {
  return vin ? `similarVins["${vin}"]` : null;
}
export function getVehiclePath(make, model, year) {
  return make && model && year ? buildVehiclePath({ make, model, year }) : null;
}

export function getPPStatus(location) {
  return get(location, 'query.disablepp', false);
}

/**
 * Build breadcrumbs
 * @param {Object} vinData Inventory data
 * @return {Array} Breadcrumbs array
 */
export function getBreadcrumbs(vinData) {
  const make = get(vinData, 'vehicleInfo.styleInfo.make', '');
  const model = get(vinData, 'vehicleInfo.styleInfo.model', '');
  const year = get(vinData, 'vehicleInfo.styleInfo.year', '');
  const type = get(vinData, 'vehicleInfo.type', '');
  const vin = get(vinData, 'vin', '');
  const submodel = first(get(vinData, 'vehicleInfo.styleInfo.subModels', []));
  const submodelIdentifier = submodel && submodel.identifier;
  const makeNiceName = getNiceName(make);
  const modelNiceName = getNiceName(model);
  const submodelIdentifierNiceName = getNiceName(submodelIdentifier);
  return compact([
    getHomeBreadcrumb(),
    {
      title: getMakeLinkText({ publicationState: type, makeName: make }),
      href: getMakeLinkUrl({ makeSlug: makeNiceName }),
    },
    {
      title: appendTrademarkCharacter({
        make,
        str: getMakeModelLinkText({ publicationState: type, makeName: '', modelName: model }),
      }),
      href: getMakeModelLinkUrl({ makeSlug: makeNiceName, modelSlug: modelNiceName }),
    },
    {
      title: appendTrademarkCharacter({
        make,
        str: getMakeModelYearLinkText({ publicationState: type, makeName: make, modelName: model, year }),
      }),
      href: getMakeModelYearLinkUrl({ makeSlug: makeNiceName, modelSlug: modelNiceName, year }),
    },
    submodelIdentifier
      ? {
          title: appendTrademarkCharacter({
            make,
            str: getMakeModelYearLinkText(
              { publicationState: type, makeName: make, modelName: model, year },
              submodelIdentifier
            ),
          }),
          href: getMakeModelYearLinkUrl(
            { makeSlug: makeNiceName, modelSlug: modelNiceName, year },
            `${submodelIdentifierNiceName}/`
          ),
        }
      : null,
    {
      title: `VIN: ${vin}`,
      href: getMakeModelYearLinkUrl({ makeSlug: makeNiceName, modelSlug: modelNiceName, year }, `vin/${vin}/`),
    },
  ]);
}

/**
 * Get seo object
 * @param state {object}
 * @param props {object}
 * @returns {object}
 */
export function getVdpSeoObject(state, props) {
  const { vin } = props.params;
  const vinPath = getVinPath(vin);
  const modelState = getModelState(state);
  const vinData = modelState.get(vinPath, InventoryModel);
  const stockPhotos = get(vinData, DATA_PATH.STOCK_PHOTOS, []).map(({ url }) => url);
  const { year, make, model } = get(vinData, 'vehicleInfo.styleInfo', {});
  const { city, stateCode } = get(vinData, 'dealerInfo.address', {});

  if (!vinData) {
    return {};
  }

  const image = getSeoImage(vinData, stockPhotos, IMAGE_DATA_PATH);

  return {
    title: `${year} ${make} ${model} - VIN: ${vin}`,
    description: `This ${year} ${make} ${model} is in stock and for sale in ${city}, ${stateCode}. View photos and learn more about this ${year} ${make} ${model} on Edmunds.`,
    robots: {
      noIndex: true,
      noFollow: true,
    },
    image,
    jsonld: [getCarVinSchema({ vinData }), getBreadcrumbSchema(getBreadcrumbs(vinData))],
  };
}

/**
 * Constructs VDP pageName and categoryName meta information
 * @param {string} [type]
 * @param {string} [suffix]
 * @returns {Object}
 */
export function getVdpMeta(type, suffix) {
  const pageName = `${BASE_PAGE_NAME}${suffix ? `_${suffix}` : ''}`;

  const pageMeta = {
    name: pageName,
    category: BASE_CATEGORY_NAME,
  };

  if (type) {
    const pageType = isUsed(type) ? 'used' : 'new';
    pageMeta.name = `${pageType}_${pageName}`;
    pageMeta.category = `${pageType}_${BASE_CATEGORY_NAME}`;
  }

  return pageMeta;
}

/**
 * Gets data for inventory list tracking.
 * @param {object} vinData
 * @param {boolean} forceLease
 * @param {boolean} isNonTMVDealer
 * @param {boolean} isCpoType
 * @param {boolean} radius
 * @param {object} photo360
 * @returns {object}
 */
export function getInventoryListTrackingData({ vinData, forceLease, isNonTMVDealer, isCpoType, radius, photo360 }) {
  const isNewVehicle = isNew(vinData.type);
  const isPartner = isPartnerDealer(vinData);
  const parentDealershipName = get(vinData, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
  const isDrivewayDealer = isDriveway(parentDealershipName);
  const isBolTreatment = isNewVehicle && isPartner && isDrivewayDealer;
  const trackingData = {
    inventory: [vinData],
    creativeId: getVinOverviewId(isCpoType),
    photo360,
  };

  if (radius) {
    trackingData.radius = radius;
  }

  if (forceLease) trackingData.subaction_name = 'show_lease_it_now';
  if (!forceLease || isBolTreatment) trackingData.subaction_name = 'show_buy_it_now';
  if (isNonTMVDealer) trackingData.subaction_name = 'forced_msrp_payment';

  return trackingData;
}

export const getIsNonTMVDealer = franchiseId => NON_TMV_DEALERS.includes(franchiseId);

export const isSellerFeesDisclaimerEligible = vinData => {
  if (!vinData) {
    return false;
  }

  const dealerGuaranteedPrice = vinData.computedDisplayInfo?.transparentPricingCompliance?.guaranteedPrice;
  const displayPrice = vinData.prices?.displayPrice;
  const type = vinData.type;

  const typePriceFieldMap = {
    [INVENTORY_TYPES_LOWERCASE.NEW]: dealerGuaranteedPrice,
    [INVENTORY_TYPES_LOWERCASE.USED]: displayPrice,
    [INVENTORY_TYPES_LOWERCASE.CPO]: displayPrice,
  };

  return !!typePriceFieldMap[type.toLowerCase()];
};

export const FEDERAL_INCENTIVES_MAX_VIN_PRICE = 25000;

export const checkVinApplicableForFederalIncentives = vinData =>
  getPublicationStateByType(vinData.type) !== PUB_STATES_LOWERCASE.USED ||
  vinData.prices?.displayPrice <= FEDERAL_INCENTIVES_MAX_VIN_PRICE;
