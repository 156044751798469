import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { PUB_STATES } from 'client/constants/pub-states';

import { Link } from 'site-modules/shared/components/link/link';
import { YmmsDropdowns } from 'site-modules/shared/components/ymms-dropdowns/ymms-dropdowns';

import './deals-module.scss';

const DEALS_MODULE = {
  header: 'Get the best deal on your new car',
  subheader: 'Ready for a new car? We’ll help you find the best deals available near you.',
  badge: 'Edmunds Recommends',
};

const CREATIVE_ID = 'deals_module_vehicle_entry';

function DealsModuleUI({ className, isMobile }) {
  const [{ year, make, model }, setMmy] = useState({ year: null, make: null, model: null });

  function trackSubmit() {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_MMY_SELECT,
        subaction_name: TrackingConstant.YEAR_SELECT,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.SELECT_CHANGE,
        creative_id: CREATIVE_ID,
        value: year,
      },
    });
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_MMY_SELECT,
        subaction_name: TrackingConstant.MAKE_SELECT,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.SELECT_CHANGE,
        creative_id: CREATIVE_ID,
        value: make,
      },
    });
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_MMY_SELECT,
        subaction_name: TrackingConstant.MODEL_SELECT,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.SELECT_CHANGE,
        creative_id: CREATIVE_ID,
        value: model,
      },
    });
  }

  const link = `/${make}/${model}/${year}/deals/`;

  return (
    <aside
      className={classnames('deals-module', className)}
      aria-labelledby="deals-module-container-label"
      data-tracking-parent="deals_module"
    >
      <div
        id="deals-module-container"
        className="deals-module-container rounded px-1_5 pt-2_5 pos-r bg-white"
        data-tracking-parent={CREATIVE_ID}
        data-tracking-value="deals-module"
      >
        <div className="deals-module-badge text-white bg-success size-12 fw-bold px-0_5 py-0_25">
          <i className="icon-fire me-0_5" aria-hidden />
          {DEALS_MODULE.badge}
        </div>
        <div id="deals-module-container-label" className="heading-4 mb-1_5 text-gray-darker">
          {DEALS_MODULE.header}
        </div>
        {DEALS_MODULE.subheader && <p className="display-lg mb-1 mb-md-2">{DEALS_MODULE.subheader}</p>}
        <Row>
          <Col xs={12} md={10} xl={8}>
            <YmmsDropdowns
              dropdownPlaceholders={{ make: 'Make', model: 'Model', year: 'Year' }}
              dropdownColSize={{ make: { xs: 12, md: 4 }, model: { xs: 12, md: 4 }, year: { xs: 12, md: 4 } }}
              hasModelsDropdown
              hasSubmodelsDropdown={false}
              hasStylesDropdown={false}
              useStyledSelects
              onChange={setMmy}
              formGroupClassName="mb-1"
              pubStates={[PUB_STATES.NEW, PUB_STATES.NEW_USED]}
            />
          </Col>
          <Col xs={12} md={2} xl={4}>
            <Button
              size="lg"
              tag={Link}
              to={link}
              onClick={trackSubmit}
              color="success"
              className={classnames('submit-btn text-transform-none text-white btn-responsive-lg-down mb-1', {
                'w-100': isMobile,
              })}
              data-tracking-id="deals_module_cta"
              data-tracking-value={`${year}_${make}_${model}`}
              disabled={!(year && make && model)}
            >
              <span className="size-16">Go</span>
            </Button>
          </Col>
        </Row>
      </div>
    </aside>
  );
}

DealsModuleUI.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

DealsModuleUI.defaultProps = {
  className: null,
  isMobile: false,
};

const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
});

export const DealsModule = connect(mapStateToProps)(DealsModuleUI);
