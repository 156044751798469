import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Badge } from 'reactstrap';
import { getFullImageUrl, getValidImageUrl } from 'client/utils/image-helpers';
import { Link } from 'site-modules/shared/components/link/link';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';

import './news-widget-card.scss';

export function NewsWidgetCard({ entry, isNewsArticleEntry, isVideoCard, trackingValue, isMobile }) {
  let cardTitle;
  let url;
  let image;
  let sponsor;

  if (isVideoCard) {
    ({ thumbnailURL: image, videoTitle: cardTitle, url } = entry);
  } else if (isNewsArticleEntry) {
    ({ title: cardTitle, image, relativeUrl: url } = entry);
  } else {
    ({ url, 'media-image': image, 'promo-text': cardTitle, 'sponsored-title': sponsor } = entry);
  }

  if (!cardTitle || cardTitle === '&nbsp;') {
    cardTitle = entry.title;
  }

  return (
    <div
      className={classnames('news-widget-card mt-1 mt-md-0', { 'video-card': isVideoCard })}
      style={isMobile ? { height: '230px' } : undefined}
    >
      <Link
        to={isVideoCard ? `${url}#videos` : url}
        className="news-card-link d-block h-100 w-100 d-flex flex-column justify-content-end align-items-start"
        data-tracking-id="news_widget_frame_click"
        data-tracking-value={trackingValue}
      >
        <RenderWhenViewable verticalOffset="100%">
          <figure
            className="image-container w-100 h-100"
            style={
              image
                ? {
                    backgroundImage: `url("${
                      isVideoCard
                        ? getFullImageUrl(getValidImageUrl(image)).replace('175.', '600.')
                        : image.replace('300.', '600.')
                    }")`,
                  }
                : {}
            }
          />
        </RenderWhenViewable>
        <div className="card-content text-shadow-contrast">
          {sponsor && <div className="text-white small fw-bold d-md-inline-block px-1">{sponsor}</div>}
          {isVideoCard && (
            <Badge
              color="primary"
              className="d-inline-flex align-items-center text-uppercase mb-0_5 py-0_25 px-0_75 size-10 mx-1"
            >
              <span className="icon-play_circle_outline size-12 me-0_25" aria-hidden /> Watch video
            </Badge>
          )}
          <h3 className="card-title heading-4 text-white px-1 mb-1">{cardTitle}</h3>
        </div>
      </Link>
      <div className="overlay w-100" />
    </div>
  );
}

NewsWidgetCard.propTypes = {
  entry: PropTypes.shape({}),
  isNewsArticleEntry: PropTypes.bool,
  isVideoCard: PropTypes.bool,
  trackingValue: PropTypes.string,
  isMobile: PropTypes.bool,
};

NewsWidgetCard.defaultProps = {
  entry: {},
  isNewsArticleEntry: false,
  isVideoCard: false,
  trackingValue: undefined,
  isMobile: false,
};
