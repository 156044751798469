import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import classnames from 'classnames';

import { getTitleWithNoOrphans } from 'site-modules/shared/utils/get-title-with-no-orphans';

import { Link } from 'site-modules/shared/components/link/link';
import { PersonalizedSearchQuery } from 'site-modules/shared/components/inventory/global-search/personalized-search-query/personalized-search-query';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ClippedContainer } from 'site-modules/shared/components/clipped-container/clipped-container';
import convexMaskMobile from 'site-modules/shared/components/clipped-container/mask-library/home-header-mobile';
import convexMaskDesktop from 'site-modules/shared/components/clipped-container/mask-library/convex-header';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import './home-hero-inventory.scss';

const BUTTON_DATA = [
  {
    id: 'used_btn',
    label: 'Shop Used',
    url: '/used-all/',
  },
  {
    id: 'new_btn',
    label: 'Shop New',
    url: '/new-cars-for-sale/',
  },
  {
    id: 'appraise_btn',
    label: 'Appraise My Car',
    url: '/appraisal/',
  },
];

export function HomeHeroInventory({ trackingParent, isMobile }) {
  return (
    <FeatureFlag name="disable-homepage-llm">
      {disableLlm => {
        const convexMask = (
          <ClippedContainer
            containerClasses={classnames('homepage-gradient d-flex pos-a top-0 left-0 right-0', {
              mobile: isMobile,
            })}
            mask={isMobile ? convexMaskMobile : convexMaskDesktop}
          />
        );
        const convexMaskChal = (
          <ClippedContainer
            containerClasses={classnames('homepage-gradient chal d-flex pos-a top-0 left-0 right-0', {
              mobile: isMobile,
            })}
            mask={isMobile ? convexMaskMobile : convexMaskDesktop}
          />
        );

        const header = (
          <ContentFragment
            componentToUse="h1"
            classes={classnames('text-white text-center pos-r pt-1_5 pb-1_5 mb-0 title pt-md-3', {
              desktop: !isMobile,
            })}
          >
            {getTitleWithNoOrphans('Let’s find your perfect car')}
          </ContentFragment>
        );

        const buttonsData = BUTTON_DATA.map(({ id, label, url }) => (
          <Button
            key={id}
            tag={Link}
            color="outline-blue-50"
            to={url}
            className={classnames(
              'pos-r d-flex align-items-center text-transform-none inventory-button px-0_5 py-0 rounded-8 bg-white',
              {
                medium: isMobile,
                'size-16': !isMobile,
              }
            )}
            data-tracking-id="home_page_inventory_select_tab"
          >
            {label}
          </Button>
        ));

        const buttonsContainerDefault = (
          <div
            className={classnames('d-flex align-items-center justify-content-center buttons mb-1_5', {
              mobile: isMobile,
              desktop: !isMobile,
            })}
            data-tracking-parent="edm-entry-inventory-search"
          >
            {buttonsData}
          </div>
        );

        const buttonsContainerNoSearch = (
          <div
            className={classnames('d-flex align-items-center justify-content-center buttons pb-3', {
              mobile: isMobile,
              desktop: !isMobile,
            })}
            data-tracking-parent="edm-entry-inventory-search"
          >
            {buttonsData}
          </div>
        );

        return (
          <div className="home-hero-inventory pos-r p-0" data-tracking-parent={trackingParent}>
            <Experiment name="search-79-home-search-vs-no-search" showDefault>
              <Recipe name="ctrl">
                <div className="pos-r px-1">
                  {convexMask}
                  {header}
                  <PersonalizedSearchQuery isMobile={isMobile} withLlmSearchDisabled={disableLlm} withEnterSubmit />
                </div>
                {buttonsContainerDefault}
              </Recipe>
              <Recipe name="chal">
                <div className="pos-r px-1">
                  {convexMaskChal}
                  {header}
                  {buttonsContainerNoSearch}
                </div>
              </Recipe>
            </Experiment>
          </div>
        );
      }}
    </FeatureFlag>
  );
}

HomeHeroInventory.propTypes = {
  trackingParent: PropTypes.string,
  isMobile: PropTypes.bool,
};

HomeHeroInventory.defaultProps = {
  trackingParent: null,
  isMobile: false,
};
